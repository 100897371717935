import React, { Component } from "react";



export class Header extends Component {
  render() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var introClass = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1 
      ? "col-md-12 intro-ie"
      : "col-md-12 intro";

    return (
      <header id="header">
        <div className={introClass}>
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="intro-text">
                  <h1>
                    {this.props.data ? this.props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Contacte-nos
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
