import React, { Component } from 'react'

export class iconbar extends Component {
  render() {
    return (
        <div class="icon-bar-right">
            <a href="tel:+351936044984" class="phone"><i class="fa fa-phone"></i>
                <div class="call-us">
                    <h2>Ligue-nos</h2>
                    <p>+351 936 044 984</p>
                </div>
            </a>
            <a href="https://www.facebook.com/Numispar-180003828725102/" class="facebook"><i class="fa fa-facebook"></i></a>
        </div>
    )
  }
}

export default iconbar
